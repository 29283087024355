<template>
  <div class="global-banner" v-if="$store.getters.globalBanner.isVisible">
    <div class="container">
      {{ $store.getters.globalBanner.text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlobalBanner',
}
</script>

<style lang="scss">
@import "../theme/theme";

.global-banner {
  padding: 8px 0;
  background-color: $light-orange;
}
</style>