import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'
import AuthenticationUtils from '@/utils/authentication'
import categories from './categories'
import Log from "../utils/logger";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    loadingMessage: null,
    user: null,
    wishlist: null,
    token: null,
    order: null,
    categories: null,
    shippings: null,
    payments: null,
    vatGroups: null,
    pages: null,
    manufacturers: null,
    banners: null,
    requestCount: 0,
    meta: {
      products: {
        highest_price: 0,
        free_shipping_price: 0,
      },
      locale: null,
      currency: null,
      info_email: null,
      display_global_banner: false,
      global_banner_text: "",
      limits: {
        cart_limit_only_vouchers: false,
      }
    },
  },
  getters: {
    user: (state) => state.user,
    categories: (state) => state.categories,
    isAuthenticated: (state) => {
      if (!_.isEmpty(state.token))
        return AuthenticationUtils.isValidToken(state.token)
    },
    token: (state) => {
      if (!_.isEmpty(state.token)) state.token
    },
    categoriesTree: (_) => categories.asTree(),
    productsMeta: (state) => state.meta.products,
    currentOrder: (state) => state.order,
    manufacturers: (state) => state.manufacturers,
    shipping: (state) => state.shippings,
    pages: (state) => state.pages,
    wishlist: (state) => state.wishlist,
    vatGroups: (state) => state.vatGroups,
    payment: (state) => state.payments,
    banners: (state) => state.banners,
    locale: (state) => state.meta.locale,
    currency: (state) => state.meta.currency,
    isLoading: (state) => state.requestCount > 0,
    loading: (state) => state.loading,
    loadingMessage: (state) => state.loadingMessage,
    infoEmail: (state) => state.meta.info_email,
    globalBanner: (state) => ({
      'isVisible': state.meta.display_global_banner,
      'text': state.meta.global_banner_text,
    }),
    limits: (state) => ({
      'cart': {
        'onlyAllowVouchers': state.meta.limits.cart_limit_only_vouchers,
      }
    }),
  },
  mutations: {
    loading: (state, payload) => {
      Vue.set(state, 'loading', payload)
    },
    loadingMessage: (state, payload) => {
      Vue.set(state, 'loadingMessage', payload)
    },
    login: (state, payload) => {
      Vue.$cookies.set('user', payload.customer)
      Vue.$cookies.set('token', JSON.stringify(payload.token))
      Vue.set(state, 'user', payload.customer)
      Vue.set(state, 'token', payload.token)
    },
    updateUser: (state, payload) => {
      Vue.$cookies.set('user', payload)
      Vue.set(state, 'user', payload)
    },
    logout: (state) => {
      Vue.set(state, 'user', null)
      Vue.set(state, 'token', null)
      Vue.$cookies.remove('user')
      Vue.$cookies.remove('token')
    },
    updateCart: (state, payload) => {
      if (state.user == null) {
        Vue.set(state, 'user', {})
      }
      Vue.set(state.user, 'cart', payload)
      Vue.$cookies.set('user', state.user)
    },
    updateCurrentOrder: (state, payload) => {
      if (payload != null)
        payload.price = Math.round(payload.price)
      Vue.set(state, 'order', payload)
    },
    updateVatGroups: (state, payload) => Vue.set(state, 'vatGroups', payload),
    updateShippings: (state, payload) => Vue.set(state, 'shippings', payload),
    updatePayments: (state, payload) => Vue.set(state, 'payments', payload),
    updatePages: (state, payload) => Vue.set(state, 'pages', payload),
    updateWishlist: (state, payload) => Vue.set(state, 'wishlist', payload),
    updateCategories: (state, payload) => Vue.set(state, 'categories', payload),
    updateProductsMeta: (state, payload) => Vue.set(state.meta, 'products',
        payload),
    updateManufacturers: (state, payload) => Vue.set(state, 'manufacturers',
        payload),
    updateBanners: (state, payload) => Vue.set(state, 'banners', payload),
    addRequestCount: (state, payload) => state.requestCount++,
    removeRequestCount: (state, payload) => {
      if (state.requestCount > 0) {
        state.requestCount--
      }
    },
    changeLocale: (state, payload) => Vue.set(state.meta, 'locale', payload),
    useBonus: (state, payload) => {
      Vue.set(state.order, 'bonus_value', payload)
      Vue.set(state.order, 'price', Math.ceil(state.order.price - payload))
    },
    removeBonus: (state, payload) => {
      Vue.set(state.order, 'price',
          Math.ceil(state.order.price + state.order.bonus_value))
      Vue.set(state.order, 'bonus_value', 0)
    },
    meta: (state, payload) => {
      Vue.set(state.meta, 'currency', payload.default_currency)
      Vue.set(state.meta, 'locale', payload.default_language)
      Vue.set(state.meta, 'info_email', payload.info_email)
      Vue.set(state.meta, 'display_global_banner', payload.display_global_banner)
      Vue.set(state.meta, 'global_banner_text', payload.global_banner_text)
      Vue.set(state.meta.limits, 'cart_limit_only_vouchers', payload.cart_limit_only_vouchers)
    },
    usePromo: (state, payload) => {
      Vue.set(state.order, 'promo_code', payload.hash)
      Vue.set(state.order, 'promo_value', payload.value)
    },
    removePromo: (state, payload) => {
      Vue.set(state.order, 'promo_code', null)
      Vue.set(state.order, 'promo_value', null)
    },
  },
})