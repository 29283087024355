import store from '../store'
import Vue from 'vue'
import ProductsRepository from '../repository/ProductsRepository'
import WishlistRepository from '@/repository/WishlistRepository'
import * as _ from 'lodash'
import Log from "../utils/logger";

Vue.mixin({
  methods: {
    getProducts: (
      page, perPage, categoryId, priceLow, priceHigh, manufacturerId, sort) =>
      ProductsRepository
        .getInstance()
        .getProducts(page, perPage, categoryId, priceLow, priceHigh,
          manufacturerId, sort),
    openProduct: function(productId, newWindow) {
      let data = { name: 'Product', params: { id: encodeURIComponent(productId) } }
      if (newWindow) {
        let routerData = this.$router.resolve(data)
        window.open(routerData.href, '_blank')
      } else {
        this.$router.push(data)
        this.scrollToTop()
      }
    },
    searchProducts: (page, perPage, search) => ProductsRepository
      .getInstance()
      .search(page, perPage, search),
    hasFreeShipping: function(product) {
      return this.productsMeta.free_shipping_price <= product.price && !this.isVoucher(product);
    },
    isVoucher: function(product) {
      return product.coupon_id != null && product.coupon_id !== 0;
    },
    processPrice: function(product, price = null, calculateDiscount = true) {
      let newPrice
      if (price != null) {
        newPrice = price
      } else {
        newPrice = product.price_with_vat
      }
      if (product.discount != null && calculateDiscount)
        newPrice = newPrice - (newPrice / 100 * product.discount)
      return Math.round(newPrice)
    },
    processPriceWithoutVAT: function(product) {
      let newPrice = product.price
      if (product.discount != null)
        newPrice = newPrice - (newPrice / 100 * product.discount)
      return Math.round(newPrice)
    },
    isInStock: function(product) {
      if (product.type == 'product') {
        return this.product.stock > 0
      } else if (product.type == 'master_card') {
        let stock = 0
        product.products.forEach((it) => stock += it.stock)
        return stock > 0
      }
    },
    saveRecentlyViewed(product) {
      let recentlyViewed = this.loadRecentlyViewedProducts()
      if (recentlyViewed == null) {
        recentlyViewed = []
      }
      if (!recentlyViewed.includes(product.id)) {
        recentlyViewed.push(product.id)
      } else {
        if (recentlyViewed.indexOf(product.id) > 0) {
          recentlyViewed.splice(recentlyViewed.indexOf(product.id), 1)
          recentlyViewed.unshift(product.id)
        }
      }
      this.$cookies.set('recently_viewed',
        JSON.stringify(recentlyViewed.slice(0, 4)))
    },
    loadRecentlyViewedProducts() {
      return JSON.parse(this.$cookies.get('recently_viewed'))
    },
    addToWishlist: function(product) {
      if (this.isAuthenticated()) {
        WishlistRepository.getInstance().addToWishlist(product)
          .then(() => {
            this.notifyProductAddedToWishlist()
          })
      } else {
        this.$confirm({
            message: this.$t('wishlist_unauthorized_message'),
            button: {
              yes: this.$t('login'),
              no: this.$t('cancel'),
            },
            callback: confirm => {
              if (confirm) {
                this.$router.push('/login')
              }
            },
          },
        )
      }
    },
    removeFromWishlist(product) {
      if (this.isAuthenticated()) {
        WishlistRepository.getInstance().removeFromWishlist(product)
          .then(() => {
            this.notifyProductRemovedFromWishlist()
          })
      } else {
        this.$confirm({
            message: this.$t('wishlist_unauthorized_message'),
            button: {
              yes: this.$t('login'),
              no: this.$t('cancel'),
            },
            callback: confirm => {
              if (confirm) {
                this.$router.push('/login')
              }
            },
          },
        )
      }
    },
    isInWishlist(product) {
      let found = _.filter(this.$store.getters.wishlist, (it) => {
        return it.id === product.id
      })
      return found.length > 0
    },
  },
  computed: {
    productsMeta: () => store.getters.productsMeta,
    vatGroups: () => store.getters.vatGroups,
  },
})