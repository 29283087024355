export const CZECH_TRANSLATIONS = {
  yes: 'Ano',
  no: 'Ne',
  ok: 'Ok',
  login: 'Přihlášení',
  home: 'Domů',
  search: 'Vyhledat',
  date: 'Datum',
  manufacturers: 'Výrobci',
  products: 'Produkty',
  cart: 'Košík',
  add_to_cart: 'Přidat do košíku',
  previous: 'Předchozí',
  next: 'Další',
  cancel_filter: 'Zrušit filtr',
  logout: 'Odhlásit',
  items_in_cart: 'Položky v košíku',
  username: 'Jméno a příjmení',
  password: 'Heslo',
  categories: 'Kategorie',
  cart_total: 'Celkem',
  account: 'Účet',
  orders: 'Objednávky',
  create_order: 'Vytvořit objednávku',
  product_added_to_cart: 'Produkt přidán do košíku',
  product_removed_from_cart: 'Produkt odebrán z košíku',
  something_wrong_happened: 'Něco se pokazilo',
  user_unauthorized: 'Nejste autorizován',
  order: 'Objednávka',
  address: 'Adresa',
  shipping_address: 'Poštovní adresa',
  billing_address: 'Fakturační adresa',
  logout_question: 'Odhlásit?',
  first_name: 'Jméno',
  last_name: 'Příjmení',
  email: 'Email',
  phone: 'Telefon',
  company_name: 'Firma',
  street: 'Ulice',
  house_number: 'Číslo domu',
  city: 'Město',
  zip: 'PSČ',
  state: 'Stát',
  country: 'Země',
  billing: 'Fakturace',
  billing_address_differs: 'Fakturační adresa se liší',
  last_modified: 'Nejnovější',
  recommended: 'Doporučeno',
  highest_price: 'Nejvyšší cena',
  lowest_price: 'Nejnižší cena',
  rating: 'Hodnocení',
  order_items: 'Položky objednávky',
  free_shipping: 'Poštovné zdarma',
  free_shipping_text: 'Doprava zdarma při objednávce nad __FREE_SHIPPING_PRICE__ Kč',
  free_shipping_countries_text: 'Platí jen do vybraných zemí (__FREE_SHIPPING_COUNTRIES__)',
  action: 'Akce',
  gift: 'Dárek',
  filter: 'Filtr',
  filters: 'Filtry',
  price: 'Cena',
  money_back: 'Money back',
  money_back_text: 'Pokud nejste spokojeni, vrátíme vám peníze',
  support: 'Podpora',
  support_text: 'Pokud máte nějaké dotazy, neváhejte zavolat',
  item: 'položka',
  is_required: 'Je vyžadováno',
  invalid_login: 'Neplatné uživatelské jméno nebo heslo',
  subcategories: 'Podkategorie',
  sort_by: 'Řadit podle',
  variants: 'Varianty',
  please_select_product_variant: 'Vyberte prosím variantu produktu',
  start_shopping: 'Začít nakupovat',
  more_categories: 'Další kategorie',
  newsletter_signup: 'Přihlásit se k odběru novinek',
  stay_in_the_know: 'Zůstaňte v obraze',
  sign_up_for_email: 'Zaregistrujte se',
  customer_support: 'Zákaznická podpora',
  about_us: 'O nás',
  services: 'Služby',
  items_count: 'Počet položek',
  continue: 'Pokračovat',
  back: 'Zpět',
  continue_without_account: 'Pokračovat bez účtu?',
  create_account: 'Vytvořit účet',
  account_created: 'Účet vytvořen',
  forgot_password: 'Zapomenuté heslo',
  reset_password: 'Obnovit heslo',
  reset_password_email_sent: 'Email pro resetování hesla byl odeslán',
  password_reset_successful: 'Resetování hesla proběhlo úšpěšně',
  invalid_link: 'Neplatný odkaz',
  shipping: 'Doprava',
  order_state: 'Stav objednávky',
  pending: 'Čekající',
  payed: 'Zaplaceno',
  payment: 'Platba',
  overview: 'Přehled',
  pay_order: 'Zaplatit objednávku',
  form_is_not_valid: 'Formulář není vyplněn',
  search_input_length_error: 'Prosím zadejte 3 a více znaků',
  nothing_found: 'Nic nenalezeno',
  in_stock: 'Skladem',
  not_in_stock: 'Není skladem',
  variant_not_in_stock: 'Varianta není skladem',
  recently_viewed: 'Nedávno prohlížené',
  settings: 'Nastavení',
  save: 'Uložit',
  delete: 'Smazat',
  delete_address: 'Smazat adresu?',
  ic: 'IČ',
  dic: 'DIČ',
  page_not_found: 'Tuto stránku na našem webu nemůžeme najít.',
  add_to_wishlist: 'Přidat do seznamu přání',
  remove_from_wishlist: 'Odebrat ze seznamu přání',
  wishlist_unauthorized_message: 'Pro přidání do seznamu přání se musíte přihlásit.',
  product_added_to_wishlist: 'Produkt přidán do seznamu přání',
  product_removed_from_wishlist: 'Produkt odebrán ze seznamu přání',
  cancel: 'Zrušit',
  wishlist: 'Seznam přání',
  waiting_for_payment_gateway: 'Čekání na odpověď platební brány',
  items: 'položek',
  submit: 'Odeslat',
  newsletters_subscription: '<b>Chci zasílat novinky a speciální nabídky</b><br><p class="small">(Náš newsletter můžete kdykoliv odhlásit).</p>',
  terms_check: 'Souhlasím s <a href="__TERMS_LINK__" target="_blank">obchodními podmínkami</a> a potvrzuji, že jsem se seznámil(a) s <a href="__PRIVACY_LINK__" target="_blank">informacemi o zpracování osobních údajů</a>.',
  password_is_weak: 'Slabé heslo',
  bonus_rate: 'Bonusový program',
  you_can_spend: 'Zbývá',
  use_bonus: 'Použít bonus',
  up_to: 'Až',
  remove_bonus: 'Odebrat bonus',
  cookie_consent_text: 'Tento web používá soubory cookies. Ty mají za úkol zajistit vám u nás veškeré pohodlí. ',
  cookie_consent_learn_more: 'Zjistit více',
  order_detail: 'Detail objednávky',
  last_piece: 'Poslední kus',
  sorry_no_shipping: 'Omlouváme se, ale do Vaší země zboží bohužel nezasíláme',
  add_product_out_of_stock_message: 'Nelze přidat, již není skladem',
  add_product_voucher_limits: 'Nelze přidat, lze nakupovat pouze vouchery.',
  ask_about_product: 'Dotaz k produktu',
  usable_payment_types: 'Použitelné metody platby',
  usable_shipping_types: 'Použitelné metody dopravy',
  promo_code: 'Promo kód',
  use_promo_code: 'Použít promo kód',
  remove_promo_code: 'Odebrat promo kód',
  resent_to_email: 'Odeslat znovu na email',
  close: 'Zavřít',
  selected_pickup_point: 'Vybrané výdejní místo',
  select_different_pickup_point: 'Vybrat jiné výdejní místo',
}