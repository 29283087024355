<template>
  <nav class="top-nav navbar navbar-expand-lg">
    <div class="container">
      <router-link to="/" class="navbar-brand tr-250">
        <img src="/assets/shield-icon-black.svg" alt="Logo"
             class="logo">
        <span class="d-none d-sm-inline-block d-md-inline-block d-lg-none d-xl-none">
        </span>
      </router-link>
      <div class="filler d-lg-none"></div>
      <TopBarButtons class="d-lg-none mr-2"/>
      <a class="navbar-toggler navbar-light float-sm-right" type="button"
         v-on:click="toggleMenu()">
        <span><i class="fas fa-bars"></i></span>
      </a>
      <div class="collapse navbar-collapse" :class="{'show': this.showMenu}">
        <div class="container">
          <div class="row md-w100p">
            <ul class="navbar-nav md-w100p">
              <li class="nav-item category"
                  v-for="category in $store.getters.categoriesTree.filter((c) => c.show_in_menu)"
                  :key="category.id">
                <a v-on:click="selectCategory(category)">
                  <backend-translated-text :default-value="category.title"
                                           :translations="category.translations"
                                           :translation-key="'title'"/>
                </a>
                <div v-if="category.categories.length > 0" class="child">
                  <div class="container">
                    <div class="row">
                      <div class="col-9">
                        <ul>
                          <li v-for="sub in category.categories.filter((c) => !c.highlighted)"
                              :key="sub.id">
                            <a v-on:click="selectCategory(sub)">
                              <backend-translated-text :default-value="sub.title"
                                                       :translations="sub.translations"
                                                       :translation-key="'title'"/>
                            </a>
                          </li>
                          <li v-for="sub in category.categories.filter((c) => c.highlighted)"
                              :key="sub.id"
                              class="d-lg-none d-xl-none highlighted">
                            <a v-on:click="selectCategory(sub)">
                              <backend-translated-text :default-value="sub.title"
                                                       :translations="sub.translations"
                                                       :translation-key="'title'"/>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="col-3 highlighted d-none d-sm-none d-md-none d-lg-block d-xl-block">
                        <ul>
                          <li v-for="sub in category.categories.filter((c) => c.highlighted)"
                              :key="sub.id">
                            <a v-on:click="selectCategory(sub)">
                              <backend-translated-text :default-value="sub.title"
                                                       :translations="sub.translations"
                                                       :translation-key="'title'"/>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="filler"></div>
        <TopBarButtons class="d-none d-lg-block"/>
      </div>
    </div>
  </nav>
</template>

<script>

import BackendTranslatedText from '@/components/BackendTranslatedText'
import TopBarButtons from '@/components/TopBarButtons'
import EventBus from '@/utils/eventbus'

export default {
  name: 'TopBar',
  components: {
    BackendTranslatedText, TopBarButtons,
  },
  data: function() {
    return {
      showMenu: false,
    }
  },
  methods: {
    selectCategory: function(category) {
      this.$router.push({ path: '/products/' + this.generateCategoryLink(category) })
      EventBus.$emit('selectCategory', category)
      this.toggleMenu()
    },
    toggleMenu: function() {
      this.showMenu = !this.showMenu
      if (!this.showMenu) {
        this.enableScrolling()
      } else {
        if (window.innerWidth < 992)
          this.disableScrolling()
      }
    },
    disableScrolling: function() {
      let x = window.scrollX
      let y = window.scrollY
      window.onscroll = function() {window.scrollTo(x, y)}
    },
    enableScrolling: function() {
      window.onscroll = function() {}
    },
  },
}
</script>

<style lang="scss">
@import "src/theme/theme";

.top-nav {
  background: #FFF;
  height: 70px;
  border-bottom: 1px solid $hd-gray;

  .navbar-brand {
    color: black;

    &:hover {
      opacity: 0.5;
    }

    .logo {
      display: inline-block;
      height: 50px;
      width: auto;

      @include media-breakpoint-down(md) {
        height: 32px;
        margin-right: 24px;
      }
    }
  }

  .filler {
    flex-grow: 1;
  }

  .navbar-toggler {
    background: white !important;
    -webkit-appearance: none;

    span {
      color: black;
    }
  }

  .navbar-collapse {
    .container {
      @include media-breakpoint-up(lg) {
        width: auto;
      }
    }
  }

  .navbar-collapse {
    @include media-breakpoint-down(md) {
      position: fixed;
      top: 70px;
      left: 0;
      background-color: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(10px);
      z-index: 500;
      width: 100vw;
      height: calc(100vh - 70px);
      overflow: scroll;

      .filler {
        flex-grow: 0;
      }
    }

    .category {
      display: inline-block;
      text-transform: uppercase;
      line-height: 70px;

      a {
        display: inline-block;
        color: black;
        height: 70px;
        padding: 0 12px;

        @include media-breakpoint-down(lg) {
          padding: 0 8px;
        }

        span {
          font-family: $font-family-base;
          font-weight: bold;
          font-size: 16px;
          letter-spacing: 0.1px;
          max-lines: 1;

          @include media-breakpoint-down(lg) {
            font-size: 12px;
          }
        }
      }

      @include media-breakpoint-down(md) {
        line-height: 40px;

        a {
          height: 40px;
          width: 100%;

          &:hover{
            background: black;
            color: white;
          }
        }

        .row, .col-9 {
          display: block;
          width: 100%;
          max-width: 100%;
        }

        &:hover {
          cursor: pointer;
        }

        & > .child {
          display: block !important;

          ul {
            list-style: none;
            padding-left: 16px;

            .highlighted {
              span {
                color: $primary;
              }
            }
          }
        }
      }

      @include media-breakpoint-up(lg) {
        &:hover {
          cursor: pointer;
          background-color: black;

          a {
            color: white;
          }

          & > .child {
            background: white;
            display: block;
            position: absolute;
            width: 100vw;
            left: 0;
            top: 70px;
            z-index: 800;
            box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);

            .container {
              background-color: $hd-gray;
              padding-left: 130px;

              .row {
                width: 100%;

                .highlighted {

                  ul {
                    columns: 1;
                  }

                  span {
                    font-weight: 600;
                  }
                }

                ul {
                  padding: 16px 0;
                  margin-bottom: 0;
                  column-width: auto;
                  columns: 3;
                  column-gap: 40px;

                  li {
                    line-height: 40px;
                    list-style: none;

                    a {
                      color: black;
                      height: 100%;

                      span {
                        font-size: 14px;
                        text-transform: none;
                        font-family: $font-family-base;
                        font-weight: 400;
                      }

                      &:hover {
                        color: $primary;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .child {
        display: none;
      }
    }
  }

  .nav-ico {
    width: 38px;
    height: auto;
    height: auto;
    margin-left: 32px;

    &:hover {
      opacity: 0.5;
    }
  }

  .cart {
    display: inline-block;
    position: relative;

    .count {
      position: absolute;
      top: 23px;
      right: -8px;
      display: block;
      border-radius: 100%;
      background: #ce1e1e;
      width: 24px;
      height: 24px;
      color: white;
      line-height: 24px;
      text-align: center;
      font-size: 12px;
    }
  }
}
</style>
