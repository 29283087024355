<template>
  <div id="app">
    <PageProgressBar/>
    <TopBar/>
    <GlobalBanner/>
    <router-view :key="$route.fullPath"/>
    <Footer/>
    <notifications group="notify" position="bottom right"/>
    <vue-confirm-dialog/>
    <div class="loading" :class="{'d-none': !$store.getters.loading}">
      <span class="message" v-if="$store.getters.loadingMessage != null">
        {{ $store.getters.loadingMessage }}&#8230;
      </span>
    </div>
    <cookie-law theme="blood-orange"
                class="cookie-consent"
                v-if="!isCookieConsentGiven()"
                :buttonText="$t('ok')"
                :buttonDecline="true"
                :buttonDeclineText="$t('close')"
                v-on:accept="onGiveCookieConsent()"
    >
      <div slot="message">
        {{ $t('cookie_consent_text') }}
        <a href="/page/8">{{ $t('cookie_consent_learn_more') }}</a>
      </div>
    </cookie-law>
  </div>
</template>

<script>
import TopBar from '@/components/TopBar'
import Footer from '@/components/Footer'
import PageProgressBar from '@/components/PageProgressBar'
import CookieLaw from 'vue-cookie-law'
import GlobalBanner from "@/components/GlobalBanner.vue";

export default {
  name: 'App',
  components: {
    GlobalBanner,
    TopBar, Footer, PageProgressBar, CookieLaw,
  },
  methods: {
    isCookieConsentGiven: () => window.consentGiven(),
    onGiveCookieConsent: () => window.location.reload(),
  },
}
</script>

<style lang="scss">
@import "src/theme/theme";

.cookie-consent {
  a {
    color: white;
    text-decoration: underline;

    &:hover {
      color: black;
    }
  }

  .Cookie__button--decline {
    color: white;
  }
}

.modal-dialog {
  #html-server-error {
    width: 100%;
    height: 75vh;
    border: none;
  }
}
</style>
