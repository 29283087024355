import { render, staticRenderFns } from "./TopBarButtons.vue?vue&type=template&id=8e8ca25c&scoped=true"
import script from "./TopBarButtons.vue?vue&type=script&lang=js"
export * from "./TopBarButtons.vue?vue&type=script&lang=js"
import style0 from "./TopBarButtons.vue?vue&type=style&index=0&id=8e8ca25c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e8ca25c",
  null
  
)

export default component.exports